/*
Copyright 2017-present Palantir Technologies, Inc. All rights reserved.
Licensed under the Apache License, Version 2.0.
*/
@font-face {
  font-family: "blueprint-icons-16";
  src: url("./blueprint-icons-16.ttf?c986b332b1995b8f7afb7f8d79e26b41") format("truetype"), url("./blueprint-icons-16.eot?c986b332b1995b8f7afb7f8d79e26b41#iefix") format("embedded-opentype"), url("./blueprint-icons-16.woff2?c986b332b1995b8f7afb7f8d79e26b41") format("woff2"), url("./blueprint-icons-16.woff?c986b332b1995b8f7afb7f8d79e26b41") format("woff");
}
@font-face {
  font-family: "blueprint-icons-20";
  src: url("./blueprint-icons-20.ttf?210de4361679c284104af8619be16af3") format("truetype"), url("./blueprint-icons-20.eot?210de4361679c284104af8619be16af3#iefix") format("embedded-opentype"), url("./blueprint-icons-20.woff2?210de4361679c284104af8619be16af3") format("woff2"), url("./blueprint-icons-20.woff?210de4361679c284104af8619be16af3") format("woff");
}

/*# sourceMappingURL=blueprint-icons.css.map */